<template>
  <div>
    <div class="field is-grouped is-marginless">
      <div class="control">
        <a
          @click.prevent="$emit('close')"
          class="icon is-small is-vcentered mr-2 menu-item"
        >
          <i class="fas fa-arrow-left" />
        </a>
      </div>
      <div class="control">
        <input
          class="input is-uppercase"
          :class="{ 'is-danger': errors.has('vrm') }"
          type="text"
          v-validate="'required|alpha_num|min:1|max:17'"
          placeholder="Vrm/vin"
          @keyup.enter="submit"
          v-model="search.value"
          name="value"
        >
      </div>

      <div
        v-if="$experian && !isVpp"
        class="control"
      >
        <input
          class="input"
          :class="{ 'is-danger': errors.has('mileage') }"
          type="number"
          max="500000"
          min="1"
          placeholder="Mileage"
          v-model="search.mileage"
          @keyup.enter="submit"
          name="mileage"
          v-validate="'integer|min:0|max:500000'"
        >
      </div>
      <div
        class="control"
        v-else-if="!isVpp"
      >
        <input
          class="input"
          :class="{ 'is-danger': errors.has('mileage') }"
          type="text"
          placeholder="Mileage"
          name="mileage"
          @keyup.enter="submit"
          v-validate="'integer|min:0|max:500000'"
          v-model="search.mileage"
          title="Max. 250,000 miles"
          v-tippy="{ placement: 'bottom' }"
        >
      </div>
      <div
        class="control"
        v-if="hasCustomerReference"
      >
        <input
          class="input"
          :class="{ 'is-danger': errors.has('vrm') }"
          type="text"
          v-model="search.customerReference"
          placeholder="Add a reference"
          name="customerReference"
          maxlength="30"
        >
      </div>
      <div
        class="control"
        v-if="isInsurer && hasDatePicker"
      >
        <a
          @click.prevent="toggleFilter"
          class="icon is-small is-vcentered px-4 menu-item"
        >
          <i class="fas fa-calendar-check" />
        </a>
      </div>
      <div class="control">
        <button
          @click="submit"
          class="button is-info"
          :class="{ 'is-loading': $wait.is('appheader-search') }"
        >
          Search
        </button>
      </div>
    </div>
    <transition
      enter-active-class="animated faster zoomIn"
      leave-active-class="animated faster zoomOut"
    >
      <TableFilter
        @save="search.date = $event.date"
        @close="toggleFilter"
        v-bind="{ dateFormat }"
        :is-companion="true"
        :date-min="dateRange.min"
        :date-max="dateRange.max"
        v-if="filterIsActive"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { node } from '@/plugins/axios'
import * as companion from 'modules/companion/services'
import * as provenance from 'modules/provenance/services'
import * as layout from 'modules/layout/services'
export default {
  name: 'AppHeaderSearchForm',
  components: {
    TableFilter: () =>
      import('core/modules/table-filter/components/TableFilter')
  },
  props: {
    tab: {
      type: String,
      required: true
    }
  },
  data: () => ({
    search: {
      value: '',
      mileage: '',
      date: null
    },
    filterIsActive: false,
    level: 1
  }),
  watch: {
    value() {
      this.search.value = this.value.trim().replace(' ', '').toUpperCase()
    }
  },
  computed: {
    ...mapGetters({
      isInsurer: 'auth/isInsurer',
      isOverlayNav: 'companion/isOverlayNav',
      hasCustomerReferenceAccess: 'auth/hasCustomerReference'
    }),
    hasDatePicker() {
      return this.tab === 'companion'
    },
    hasCustomerReference() {
      return this.tab === 'companion' && this.hasCustomerReferenceAccess
    },
    isVpp() {
      return this.tab === 'vpp'
    },
    isVin() {
      return this.type === 'vin'
    },
    isVrm() {
      return this.type === 'vrm'
    },
    serviceType() {
      if (this.tab === 'check') return 'AutoCheck'
      else if (this.tab === 'motorcheck') return 'Motorcheck'
      else return null
    },
    type() {
      const { length } = this.search.value
      if (length > 0 && length <= 7) return 'vrm'
      else if (length > 0 && length <= 17) return 'vin'
      else return null
    },
    dateFormat: () => 'YYYY-MM-DD',
    dateRange: () => layout.dateRange(),
    value() {
      return this.search.value
    }
  },
  methods: {
    ...mapActions('companion/similarListings/filterBy', ['clearFilters']),
    toggleFilter() {
      this.filterIsActive = !this.filterIsActive
    },
    async submit() {
      const valid = await this.$validator.validate()
      if (valid) {
        this.$wait.start('appheader-search')
        const { type, search } = this
        const { value, mileage } = search
        if (this.isVpp) this.goToVpp(search)
        else if (this.hasDatePicker) this.goToCompanion(search)
        else this.openModal(value, type, mileage)
      }
    },
    async openModal(value, type, mileage) {
      try {
        const data = await provenance.precheck({ value, type, mileage })
        this.$modal.open('provenance/precheck', {
          serviceType: this.serviceType,
          vrm: this.isVrm ? value : data.vrm,
          vin: this.isVin ? value : data.vin,
          description: provenance.buildPreDescription(data),
          mileage: data.mileage,
          submitButtonText:
            this.serviceType === 'Motorcheck' ? 'Generate Report' : 'Run Check',
          instructionsText: `Choose the ${this.serviceType} report options you would like to include.`
        })

      } catch (err) {
        this.notifyError(err)
      } finally {
        this.reset()
      }
    },
    async precheck() {
      const { type, search } = this
      const { value, mileage } = search
      const params = { [type]: value }
      if (mileage) params.mileage = mileage
      try {
        const { data } = await provenance.precheck(params)
        return data
      } catch (err) {
        this.$notify('VRM unavailable')
        this.$wait.end('appheader-search')
        throw err
      }
    },
    async goToCompanion(search) {
      const data = this.isVin ? await this.precheck() : null
      const vrm = this.isVrm ? search.value : data.vrm
      try {
        const mileage = search.mileage ? search.mileage : null
        const params = {
          vrm,
          mileage,
          date: search.date,
          customerReference: search.customerReference
        }
        await companion.value(params)
      } catch (err) {
        this.notifyError(err)
      } finally {
        this.reset()
        this.clearFilters()
      }
    },
    async goToVpp(search) {
      const data = this.isVin ? await this.precheck() : null
      const vrm = this.isVrm ? search.value : data.vrm
      try {
        await this.$router.push({ name: 'vehicle-profile', params: { vrm } })
      } catch (err) {
        if (err.name === 'NavigationDuplicated') {
          return this.$notify("You're already looking at that vehicle.")
        }

        this.notifyError(err)
      } finally {
        this.reset()
      }
    },
    clear() {
      this.search = {
        vrm: '',
        mileage: '',
        date: null
      }
    },
    notifyError(err) {
      const msg = err?.response?.data?.error?.message
      this.$notify(msg || 'Currently unable to perform a search')
    },
    reset() {
      this.$wait.end('appheader-search')
      this.$mxp.track(`appheader_${this.tab}_search`)
      this.clear()
      this.$emit('close')
    }
  }
}
</script>

<style lang="sass" scoped>
.icon
  height: 100%
</style>
